import cx from 'classnames'
import { deviceType } from 'detect-it'
import { useKeenSlider } from 'keen-slider/react'
import NextImage from 'next/image'
import { useContext } from 'react'

import { HasReviewsStrings } from '@data/sanity/queries/types/site'
import { getBasicImageLoader } from '@lib/image'
import { Reviews } from '@lib/review'
import { StringsContext } from '@lib/strings'
import { screens } from '@lib/theme'
import Icon from '@components/icon'
import Stars from '@components/stars'

interface ReviewCarouselProps extends HasReviewsStrings {
  reviews: Reviews
}

const ReviewCarousel = ({ reviews, reviewsStrings }: ReviewCarouselProps) => {
  const strings = useContext(StringsContext)

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    selector: '.review-slide',
    defaultAnimation: { duration: 800 },
    slides: { perView: 1, spacing: 40 },
    loop: true,
    drag: deviceType === 'touchOnly',
    breakpoints: {
      [`(min-width: ${screens.sm})`]: {
        slides: { perView: 2, spacing: 40 },
      },
      [`(min-width: ${screens.md})`]: {
        slides: { perView: 3, spacing: 40 },
      },
    },
  })

  return (
    <div className="container">
      <div className="flex items-center justify-between py-5 mb-4">
        <h5 className="font-semibold">{reviewsStrings.reviewsCarouselTitle}</h5>
        <div className="flex items-center flex-end star-ratings">
          <div className="flex items-center mr-5">
            <Stars ratingValue={reviews.rating} readonly />
            <p className="ml-2 mt-[2px]">{`${reviews.items.length} ${
              reviews.items.length === 1
                ? reviewsStrings.reviewsSingular
                : reviewsStrings.reviewsPlural
            }`}</p>
          </div>
          <div className="flex items-center justify-between">
            <button
              onClick={() => slider.current?.prev()}
              aria-label={strings.carouselLeftArrowLabel}
              className={cx(
                'w-8 h-8 p-2 rounded-full bg-transparent transition-colors duration-300 hover:bg-white hover:bg-opacity-20'
              )}
            >
              <Icon name="ArrowLeft" id="previous-review" className="block" />
            </button>
            <button
              onClick={() => slider.current?.next()}
              aria-label={strings.carouselRightArrowLabel}
              className={cx(
                'w-8 h-8 p-2 rounded-full bg-transparent transition-colors duration-300 hover:bg-white hover:bg-opacity-20'
              )}
            >
              <Icon name="ArrowRight" id="next-review" className="block" />
            </button>
          </div>
        </div>
      </div>

      <div
        className="flex relative overflow-hidden will-change-transform touch-action touch-action-pan-y"
        ref={sliderRef}
      >
        {reviews.items.map((review, i) => (
          <div key={i} className="review-slide">
            <div className="flex items-center justify-between mb-2">
              <Stars ratingValue={reviews.rating} readonly />
              <p className="opacity-30 text-xs">{review.date}</p>
            </div>
            <p className="font-semibold mb-2">{review.title}</p>
            <div className="flex">
              <div className="min-w-[120px] mr-5">
                {review.productImageThumbnailUrl && (
                  <div className="relative w-[120px] h-[80px] mb-2">
                    <NextImage
                      src={review.productImageThumbnailUrl}
                      loader={getBasicImageLoader}
                      fill
                      unoptimized
                      alt={review.productName ?? ''}
                      className="object-cover"
                    />
                  </div>
                )}
                <p className="opacity-60 text-xs">{review.productName}</p>
              </div>
              <div>
                <p className="text-sm italic mb-1">{review.message}</p>
                <p className="text-sm font-semibold">{review.author}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ReviewCarousel
