import cx from 'classnames'
import { useEffect, useState } from 'react'
import { Rating } from 'react-simple-star-rating'

interface StarsProps {
  ratingValue: number
  maxValue?: number
  size?: number
  readonly?: boolean
  className?: string
  onClick?: (value: number) => void
}

const Stars = ({
  ratingValue,
  maxValue = 5,
  size = 18,
  readonly,
  className,
  onClick = () => null,
}: StarsProps) => {
  // Prevents server side loading to avoid this bug (https://github.com/awran5/react-simple-star-rating/issues/40)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (isLoaded) {
      return
    }

    setIsLoaded(true)
  }, [isLoaded])

  if (!isLoaded) {
    return
  }

  return (
    <Rating
      className={cx('stars', className)}
      emptyClassName="stars-empty"
      fillClassName="stars-full"
      initialValue={ratingValue}
      allowFraction
      readonly={readonly}
      size={size}
      iconsCount={maxValue}
      onClick={onClick}
      // Star colors are set in tailwind.config.ts
      fillColor="transparent"
      emptyColor="transparent"
    />
  )
}

export default Stars
