import axios, { AxiosResponse } from 'axios'

import {
  SanityModule,
  SanityModuleType,
  SanityReviewWidgetModule,
  SanityReviewWidgetType,
} from '@data/sanity/queries/types/modules'
import { Locale } from './language'

interface StampedWidgetReviewOption {
  message: string
  value: string
}

export interface StampedWidgetReview {
  id: number
  author: string
  reviewTitle?: string
  reviewMessage?: string
  reviewRating: number
  reviewDate: string
  reviewUserPhotos?: string
  reviewUserVideos?: string
  reviewVerifiedType: number
  reviewReply?: string
  reviewReplyDate?: string
  productId: number
  productName?: string
  productSKU?: string
  productVariantName?: string
  shopProductId: number
  productUrl?: string
  productImageUrl: string
  productImageLargeUrl: string
  productImageThumbnailUrl?: string
  productDescription?: string
  avatar?: string
  location: string
  countryIso?: string
  reviewVotesUp: number
  reviewVotesDown: number
  dateCreated: string
  dateReplied?: string
  reviewType: number
  widgetType: ''
  reviewOptionsList: StampedWidgetReviewOption[]
  featured: boolean
}

interface StampedWidgetReviewsResponse {
  c?: boolean
  page: number
  data: StampedWidgetReview[]
  lang: string
  shop: string
  template: string
  elementId: number
  total: number
  totalAll: number
  totalAllWithNPS: number
  rating: number
  ratingAll: number
  translations: Record<string, string>
}

export interface Review {
  author: string
  avatar: string | null
  country: string | null
  countryCode: string | null
  date: string
  isVerified: boolean
  message: string | null
  productImageThumbnailUrl: string | null
  productName: string | null
  productVariantName: string | null
  rating: number
  title: string | null
}

export interface Reviews {
  rating: number
  items: Review[]
}

export interface StampedReviewInput {
  author: string
  email: string
  location?: string
  reviewRating: number
  reviewTitle: string
  reviewMessage: string
  productId: number
  productName?: string
  productSKU?: string
  productImageUrl?: string
  productUrl?: string
}

export interface StampedCreateReviewResponse {
  reviewCreated: boolean
  error?: string
}

/**
 * Parses Stamped review response.
 */
const parseStampedReviews = (
  reviewsResponse: StampedWidgetReviewsResponse
): Reviews => {
  return {
    rating: reviewsResponse.rating,
    items: reviewsResponse.data.map((review) => ({
      author: review.author,
      avatar: review.avatar ?? null,
      country: review.location ?? null,
      countryCode: review.countryIso ?? null,
      date: review.reviewDate,
      isVerified: review.reviewVerifiedType === 2,
      message: review.reviewMessage ?? null,
      productImageThumbnailUrl: review.productImageThumbnailUrl ?? null,
      productName: review.productName ?? null,
      productVariantName: review.productVariantName ?? null,
      rating: review.reviewRating,
      title: review.reviewTitle ?? null,
    })),
  }
}

/**
 * Gets Stamped reviews for all products.
 */
const getStampedReviews = async (
  shopifyDomain: string,
  stampedApiKey: string,
  limit: number
): Promise<Reviews> => {
  try {
    const response = await axios.get<StampedWidgetReviewsResponse>(
      `https://stamped.io/api/widget/reviews`,
      {
        params: {
          minRating: 1,
          take: limit,
          page: 1,
          storeUrl: shopifyDomain,
          apiKey: stampedApiKey,
          sortReviews: 'featured',
        },
      }
    )

    return parseStampedReviews(response.data)
  } catch (error) {
    return {
      rating: 0,
      items: [],
    }
  }
}

/**
 * Gets Stamped reviews for a specific product.
 */
const getStampedProductReviews = async (
  shopifyDomain: string,
  stampedApiKey: string,
  shopifyProductId: number,
  limit: number
): Promise<Reviews> => {
  try {
    const response = await axios.get<StampedWidgetReviewsResponse>(
      `https://stamped.io/api/widget/reviews`,
      {
        params: {
          productId: shopifyProductId,
          minRating: 1,
          take: limit,
          page: 1,
          storeUrl: shopifyDomain,
          apiKey: stampedApiKey,
          sortReviews: 'featured',
        },
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    return parseStampedReviews(response.data)
  } catch (error) {
    return {
      rating: 0,
      items: [],
    }
  }
}

/**
 * Gets reviews required by page modules.
 */
export const getReviews = async (
  modules: SanityModule[],
  shopifyDomain: string,
  stampedApiKey?: string,
  shopifyProductId?: number
): Promise<Reviews> => {
  // Check if page has review modules
  const hasReviewModule = !!modules.some(
    ({ _type }) => _type === SanityModuleType.REVIEW_WIDGET
  )
  const hasAllReviewModule = !!modules.some(
    (module) =>
      module._type === SanityModuleType.REVIEW_WIDGET &&
      'type' in module &&
      module.type === SanityReviewWidgetType.MAIN
  )

  if (!stampedApiKey || !hasReviewModule) {
    return {
      rating: 0,
      items: [],
    }
  }

  if (hasAllReviewModule) {
    return await getStampedReviews(shopifyDomain, stampedApiKey, 100)
  }

  // Get reviews filtered by product ID
  if (!shopifyProductId) {
    return {
      rating: 0,
      items: [],
    }
  }

  return await getStampedProductReviews(
    shopifyDomain,
    stampedApiKey,
    shopifyProductId,
    100
  )
}

/**
 * Creates a new review in Stamped.io.
 */
export const createStampedReview = async (
  locale: Locale,
  reviewInput: StampedReviewInput
) => {
  try {
    const response = await axios.post<
      StampedCreateReviewResponse,
      AxiosResponse<StampedCreateReviewResponse>,
      StampedReviewInput
    >('/api/stamped/create-review', reviewInput, {
      headers: {
        'Content-Type': 'application/json',
        'X-Locale': locale,
      },
    })

    return response.data.reviewCreated
  } catch (error) {
    return false
  }
}

export enum ReviewProvider {
  STAMPED = 'stamped',
}

export interface ReviewOptions {
  provider: ReviewProvider
  apiKey?: string
}

interface StampedProductReview {
  id?: number
  author?: string
  reviewTitle?: string
  reviewMessage?: string
  reviewRating?: 1 | 2 | 3 | 4 | 5
  reviewDate?: string
  reviewVerifiedType?: number
  productId?: number
  productName?: string
  productSKU?: string
  productVariantName?: string
  shopProductId?: number
  productUrl?: string
  productImageUrl?: string
  productImageLargeUrl?: string
  productImageThumbnailUrl?: string
  avatar?: string
  location?: string
  reviewVotesUp?: number
  reviewVotesDown?: number
  dateCreated?: string
  reviewType?: number
  widgetType?: string
  reviewOptionsList?: never[]
  featured?: boolean
}

interface StampedProductReviews {
  data: StampedProductReview[]
}

export type ProductReview = StampedProductReview

interface StampedBadgePayloadProductId {
  productId: number
}

interface StampedBadgePayload {
  productIds: StampedBadgePayloadProductId[]
  apiKey: string
  storeUrl: string
}

interface StampedReviewSummary {
  rating: number
  count: number
  countQuestions: number
  c: boolean
  badge: string
  badgeqna: string
}

type StampedBadgeResponse = StampedReviewSummary[]

export interface ProductReviewSummary {
  ratingCount: number
  ratingValue?: number
}

/**
 * Gets all product reviews from Stamped API.
 */
export const getProductReviews = async (
  shopifyDomain: string,
  productId: number,
  options: ReviewOptions
): Promise<ProductReview[]> => {
  switch (options.provider) {
    case ReviewProvider.STAMPED: {
      if (!options.apiKey) {
        return []
      }

      const response = await axios.get<StampedProductReviews>(
        'https://stamped.io/api/widget/reviews',
        {
          params: {
            productId,
            apiKey: options.apiKey,
            storeUrl: shopifyDomain,
            minRating: 1,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      return response.data.data
    }

    default: {
      return []
    }
  }
}

/**
 * Gets product review summary from Stamped API.
 */
export const getProductReviewSummary = async (
  shopifyDomain: string,
  productId: number,
  options: ReviewOptions
): Promise<ProductReviewSummary> => {
  switch (options.provider) {
    case ReviewProvider.STAMPED: {
      if (!options.apiKey) {
        return {
          ratingCount: 0,
        }
      }

      const payload: StampedBadgePayload = {
        productIds: [
          {
            productId,
          },
        ],
        apiKey: options.apiKey,
        storeUrl: shopifyDomain,
      }

      const response = await axios.post<
        StampedBadgeResponse,
        AxiosResponse<StampedBadgeResponse>,
        StampedBadgePayload
      >('https://stamped.io/api/widget/badges', payload, {
        params: {
          isIncludeBreakdown: 'false',
          isincludehtml: 'false',
        },
        headers: {
          'Content-Type': 'application/json',
        },
      })

      return {
        ratingCount: response.data[0]?.count ?? 0,
        ratingValue: response.data[0]?.rating,
      }
    }

    default: {
      return {
        ratingCount: 0,
      }
    }
  }
}
