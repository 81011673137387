import {
  SanityReviewWidget,
  SanityReviewWidgetType,
} from '@data/sanity/queries/types/modules'
import { Reviews } from '@lib/review'

import ReviewCarousel from './review-carousel'
import ReviewList from './review-list'

type ReviewWidgetProps = SanityReviewWidget & {
  reviews: Reviews
}

const ReviewWidget = ({ type, reviews, reviewsStrings }: ReviewWidgetProps) => {
  switch (type) {
    case SanityReviewWidgetType.MAIN: {
      return (
        <ReviewCarousel reviews={reviews} reviewsStrings={reviewsStrings} />
      )
    }

    case SanityReviewWidgetType.PRODUCT: {
      return <ReviewList reviews={reviews} reviewsStrings={reviewsStrings} />
    }

    default: {
      return null
    }
  }
}

export default ReviewWidget
